$(function () {

  if (window.location.hash == '#applyForm') {
    $("a[href='#applyForm']").click();
  }


});

try {
  /**
   * Menu tree toggle functionality.
   *
   * @type {{attach: Drupal.behaviors.solutionsMenu.attach}}
   */
  Drupal.behaviors.solutionsMenu = {
    attach: function () {

      // Hackish. Sticky stuff does not work correctly in some cases,
      // so we need to trigger recalc.
      window.setTimeout(function () {
        $(document.body).trigger("sticky_kit:recalc");
      }, 400);

      $('.nav.sidebar-nav .expanded .square-nav-caret').once().on('click', function (e) {
        e.preventDefault();

        $(this).parent().parent().find('> ul').slideToggle(400, function () {
          // Recalculate menu height.
          $(document.body).trigger("sticky_kit:recalc");
        });
        $(this).parent().parent().toggleClass('open');

      })
    }
  };

  /**
   * Clone partners block to the bottom.
   *
   * @type {{attach: Drupal.behaviors.partnersBlock.attach}}
   */
  Drupal.behaviors.partnersBlock = {
    attach: function () {
      if ($('#block-hansab-custom-solution-partners-clone').length === 0) {
        var $partners = $('#block-hansab-custom-solution-partners');
        // Create cloned block, change its id and insert it after the content area.
        var $cloned = $partners.clone().prop('id', 'block-hansab-custom-solution-partners-clone').insertAfter('.content-wrapper');

        // Add some visibility classes.
        $partners.addClass('visible-md-block visible-lg-block');
        $cloned.addClass('hidden-md hidden-lg block-partners-cloned');
      }
    }
  };

  /**
   * Create new tag for new products and initialize product slider.
   *
   * @type {{attach: Drupal.behaviors.hansabNewProducts.attach}}
   */
  Drupal.behaviors.hansabNewProducts = {
    attach: function () {

      if ($('.view-new-products').length === 0) {
        return;
      }

      $('.view-new-products .products-panel').each(function () {
        if ($(this).find('.new-product-tag').length === 0) {
          $(this).prepend('<span class="new-product-tag">' + Drupal.t('new') + '</span>');
        }
      });

      $('.view-new-products .view-content').owlCarousel({
        loop: false,
        rewind: true,
        nav: true,
        navText: ['<span class="icon-angle-left"></span>', '<span class="icon-angle-right"></span>'],
        responsive: {
          0: {
            items: 1
          },
          768: {
            items: 2
          },
          1200: {
            items: 3
          },
          1600: {
            items: 4
          }
        },
        onDragged: function () {
          // Call render method from echo library to load images again.
          echo.render();
        },
        onTranslated: function () {
          // Call render method from echo library to load images again.
          echo.render();
        }
      });

      $('.view-new-products .owl-nav > div').on('click', function () {
        // Call render method from echo library to load images again.
        echo.render();
      })
    }
  }
} catch (err) {
  console.log(err);
}