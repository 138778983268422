$.fn.frontVideo = function () {

  $(this).each(function () {

    var wrapper = $(this);
    var video = wrapper.find("video:first");
    var playButton = wrapper.find(".play-button:first");
    var output = '<div class="fullscreen-video"><div class="close-button"></div><video width="100%" height="100%" autoplay controls><source src="' + video.attr("data-mp4") + '" type="video/mp4"><source src="' + video.attr("data-ogg") + '" type="video/ogg"></video></div>';
    var outputObj = '';

    playButton.bind("click", function (e) {
      e.preventDefault();
      startFullScreen();
    });

    function startFullScreen() {

      video[0].pause();

      $("body").append(outputObj = $(output));

      outputObj.find(".close-button").bind("click", function (e) {
        e.preventDefault();
        closeFullScreen();
      });

    };

    function closeFullScreen() {
      outputObj.fadeOut(500, function () {
        outputObj.remove();
        video[0].play();
      });
    };

  });
};