$(function(){
  $("form :input[type=text]").each(function(index, elem) {
    var eId = $(elem).attr("id");
    var label = null;
    if (eId && (label = $(elem).parents("form").find("label[for="+eId+"]")).length == 1) {
      label.find("span").remove();//remove span elements
      if ($(elem).hasClass('required')) {
        $(elem).attr("placeholder", $(label).html() + '*');
      }
      $(label).remove();
    }
  });
  $("form :input[type=email]").each(function(index, elem) {
    var eId = $(elem).attr("id");
    var label = null;
    if (eId && (label = $(elem).parents("form").find("label[for="+eId+"]")).length == 1) {
      label.find("span").remove();//remove span elements
      if ($(elem).hasClass('required')) {
        $(elem).attr("placeholder", $(label).html() + '*');
      }
      $(label).remove();
    }
  });
});
