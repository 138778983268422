$.fn.equalHeight = function () {

  var instances = $(this);

  instances.each(function () {

    var main = $(this);
    var className = 'panel-footer';

    if (main.find(".price-footer").size() > 0) {
      var className = 'title';
    } else {
      var className = 'panel-footer';
    }

    var columns = main.find("." + className);
    var id = "equal-" + Math.random().toString(36).substr(2);

    main.attr("id", id);



    var style;
    $("body").append(style = $('<style></style>'));

    function calculate() {

      style.empty();

      var maxHeight = 0;

      columns.each(function () {
        var height = $(this).outerHeight();
        if (height > maxHeight) {
          maxHeight = height;
        }
      });

      var output = '#' + id + ' .' + className + '{height: ' + maxHeight + 'px;}';
      style.text(output);

    }

    calculate();

    main.bind("new-heights", function () {
      calculate();
    });

  });

  var timeout;

  $(window).bind("resize", function () {
    clearTimeout(timeout);
    timeout = setTimeout(function () {
      instances.trigger("new-heights");
    }, 25);

    $('.video_frame').each(function () {

      var width = $('.main-container-content').width();
      $(this).data('width', width);
    });

  });

};