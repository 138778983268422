$.fn.equalSteps = function () {

  var instances = $(this);

  instances.each(function () {

    var main = $(this);
    //var className = 'media-middle';
    var stepTitleClass = 'media-middle';
    var stepBodyClass = 'process-steps-body';

    var stepTitle = main.find("." + stepTitleClass);
    var stepBody = main.find("." + stepBodyClass);

    var id = "equal-" + Math.random().toString(36).substr(2);

    main.attr("id", id);


    var style;
    $("body").append(style = $('<style></style>'));

    function calculate() {

      style.empty();

      var maxTitleHeight = 0;
      var maxBodyHeight = 0;

      stepTitle.each(function () {
        var height = $(this).outerHeight();
        if (height > maxTitleHeight) {
          maxTitleHeight = height;
        }
      });

      stepBody.each(function () {
        var height = $(this).outerHeight();
        if (height > maxBodyHeight) {
          maxBodyHeight = height;
        }
      });

      var output = '#' + id + ' .' + stepTitleClass + '{height: ' + maxTitleHeight + 'px;}' + ' ' + '#' + id + ' .' + stepBodyClass + '{height: ' + maxBodyHeight + 'px;}';
      style.text(output);

    }

    calculate();

    main.bind("new-heights", function () {
      calculate();
    });

  });

  var timeout;

  $(window).bind("resize", function () {
    clearTimeout(timeout);
    timeout = setTimeout(function () {
      instances.trigger("new-heights");
    }, 25);

  });

};