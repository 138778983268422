	/* document.ready */
	$(function () {
		$(".header-search").headerSearchDropdown();

		$(".products-panels").equalHeight();
		$(".process-steps").equalSteps();

		$(".logo-menu-block img").addClass("grayscale");


		$('#report_a_problem').on('click', function(e) {
			e.preventDefault();
			var row = $(this).parents('.row');
			var largerCol = row.find('.col-lg-9');
			var smallerCol = row.find('.col-lg-3');

			largerCol.removeClass('col-lg-9').addClass('col-lg-8');
			smallerCol.removeClass('col-lg-3').addClass('col-lg-4');
			row.find(".serviceHelp").hide();
			row.find('.contactCard').show();

		});

		$('[data-action="benefits"]').on('click', function(e) {
			e.preventDefault();
			if( $('.contract__button').is(':visible')) {
				$('.contract__button').hide();
				$('.contract__body').show()
			} else {
				$('.contract__button').show();
				$('.contract__body').hide();
			}
		});

		$('.service .service__card').each(function(){
			var obj = $(this);
			var btn = obj.find('.service__card__morebtn a, .service__card__lessbtn a');
			btn.on('click', function(e){
				e.preventDefault();
				obj.toggleClass('service__card--active');
			});
		});

		$('[data-plugin="video-player"]').each(function() {
			var main = $(this);
			var url = main.attr('data-video');
			var params = url.split('?')[1].split("&").reduce(function(prev, curr, i, arr) {
				var p = curr.split("=");
				prev[decodeURIComponent(p[0])] = decodeURIComponent(p[1]);
				return prev;
			}, {});
			var iframe = '<iframe src="https://www.youtube.com/embed/'+params.v+'?autoplay=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>';
			main.append('<div class="video-player__image" style="background-image:url(https://img.youtube.com/vi/'+params.v+'/0.jpg);"></div>');
			main.append('<div class="video-player__button"></div>');

			main.on('click', function() {
				main.removeAttr('style');
				main.append(iframe);
				main.off('click');
			});
		});
		$('[data-plugin="swiper"]').each(function() {
			var main = $(this);
			var minHeight = 200;
			var shadowHeight = 125;
			var debounce;

			$(window).on('resize', function() {
				clearTimeout(debounce);
				debounce = setTimeout(function(){
					calcHeight();
				}, 300);
			}).trigger('resize');

			loadImages(main, calcHeight);

			function calcHeight() {
				var maxHeight = 0;
				main.find('.slides__card').each(function(){ 
					var height = $(this).outerHeight();
					maxHeight = maxHeight < height ? height : maxHeight;
				});

				main.css('height', maxHeight);
			}

			main.parent().append('<div class="swiper-button-next"></div><div class="swiper-button-prev"></div>');

			var swiper = new Swiper($(this)[0], {
				slidesPerView: 1,
				loop: true,
				watchSlidesProgress: true,
				navigation: {
          nextEl: main.parent().find('.swiper-button-next')[0],
          prevEl: main.parent().find('.swiper-button-prev')[0],
        },
				breakpoints: {
					1000: {
						slidesPerView: 3,
					},
					1599: {
						slidesPerView: 4,
					}
				},
				pagination: {
					el: main.parent().find('.swiper-pagination')[0],
					type: 'bullets',
					clickable: true,
				},
				on: {
					progress: function() {
						
						var swipes = this;
						for (var i = 0; i < swipes.slides.length; i++) {
							var slide = swipes.slides[i];
							var slideHeight = slide.querySelector('.slides__card').offsetHeight;
							var progress = parseFloat(slide.progress.toFixed(2));
							var wrapper = slide.querySelector('.slides__wrapper');
							var shadow = slide.querySelector('.slides__shadow');
							
							var slidesHack = (swipes.params.slidesPerView-1) * (-1);
							var minSize = 0.75;
							if (progress <= 0 && progress > slidesHack) {
								if (progress < slidesHack + 1) {
									progress = slidesHack + Math.abs(progress);
								}
								var min = 0.75;
								var max = 1;

								progress = parseFloat(Math.abs(progress).toFixed(2));
								progress = progress > max ? max : progress;
								var size = (minSize + ((1-minSize)*progress)) * 100;
								
								var opacityMin = 0.5;
								var opacity = (opacityMin + ((1-opacityMin)*progress)) * 100;

								wrapper.style.transform = 'scale('+size+'%)';
								wrapper.style.opacity = opacity  +'%';
							} else {
								if (swipes.params.slidesPerView === 1) {
									wrapper.style.transform = 'scale(100%)';
									wrapper.style.opacity = '1';
								} else {
									wrapper.style.transform = 'scale('+(minSize * 100)+'%)';
									wrapper.style.opacity = opacityMin;
								}
							}
							
						} 
						
					},
				}
			});
		});
		if (!device.mobile() && !device.tablet()) {

			$(window).bind("resize sticky-resize", function () {
				if ($(window).width() < 992) {
					$(".sidebar-wrapper, .content-wrapper").trigger("sticky_kit:detach");
				} else {
					if ($(".sidebar-wrapper, .content-wrapper").size() > 0) {
						$(".sidebar-wrapper, .content-wrapper").stick_in_parent();
					};
				}
			}).trigger("sticky-resize");

			$(".countUpSpan").each(function () {
				var obj = $(this);
				var objValue = parseInt(obj.text());
				var targetId = obj.attr("id");


				var options = {
					useEasing: true,
					useGrouping: false,
					separator: ',',
					decimal: '.',
					prefix: '',
					suffix: ''
				};

				obj.css("width", obj.width());

				obj.hover(function () {

					var demo = new CountUp(targetId, 1, objValue, 0, 5, options);

					demo.start();
				}, function () {

				});

			});
		};



		$("a[href='#applyForm']").click(function () {
			$(this).hide();
		});
		//$(".partners-group-tabs").collapseContent();

		$('.video_frame').each(function () {

			var width = $('.main-container-content').width();
			$(this).data('width', width);
		});
		$(document).delegate('*[data-toggle="lightbox"]', 'click', function (event) {
			event.preventDefault();
			$(this).ekkoLightbox();
		});

		$('.partners-group-tabs a').on('click', function (e) {
			$('.partners-group-tabs div[role="tabpanel"]').removeClass('active in');
			$('.partners-group-tabs li.tab').removeClass('active');
		})

		if ($(".front-video-wrapper").size() > 0) {
			$(".front-video-wrapper").frontVideo();
		}

		// MOBILE PRODUCTS MENU  BY SELECT

		// Create the dropdown bases

		var sidebarMenuWrapper = $(".sidebar-nav").parent("nav");

		$("<select class='sidebar-nav-select' />").appendTo(sidebarMenuWrapper);

		var optionValue = $(".sidebar-nav").attr("data-option");
		if (!optionValue) {
			try {
				optionValue = Drupal.t('Menu');
			} catch(err) {
				console.log(err);
			}
		}
		// Create default option "Go to..."
		$("<option />", {
			"selected": "selected",
			"value": "",
			"text": optionValue
		}).appendTo(".sidebar-nav-select");

		// Populate dropdowns with the first menu items
		$(".sidebar-nav li a").each(function () {
			var obj = $(this);

			depth = obj.parents('ul').length - 1,
				indent = '';
			if (depth) {
				while (depth > 0) {
					indent += ' - ';
					depth--;
				}
			}

			$("<option />", {
				"value": obj.attr("href"),
				"text": indent + ' ' + obj.text()
			}).appendTo(".sidebar-nav-select");
		});

		//make responsive dropdown menu actually work			
		$(".sidebar-nav-select").change(function () {
			window.location = $(this).find("option:selected").val();
		});

		sidebarMenuWrapper.customForm();
		$('[data-plugin="filters"]').filters();
	});

function loadImages(obj, callback) {
	var images = obj.find('img');
	var urls = [];
	images.each(function() {
		urls.push($(this).attr('src'));
	});
	urls.forEach(function(item){
		var tmp = new Image();
		tmp.onload = function() {
			callback();
		}
		tmp.src = item;
	});

}


$.fn.filters = function() {
	var main = $(this);
	var buttons = main.find('button[name]');
	var items = $('[data-filters="items"]:first').find('li');
	
	buttons.on('click', function(e){ 
		e.preventDefault();
		toggle($(this).attr('name'));
	});

	function toggle(name) {
		buttons.removeClass('active').filter('[name="'+name+'"]').addClass('active');
		if (name === 'all') {
			items.show();
		} else {
			items.hide().filter('[data-category*="'+name+'"]').show();
		}
	}

	toggle('all');
}

document.createElement('header');
document.createElement('nav');
document.createElement('section');
document.createElement('article');
document.createElement('aside');
document.createElement('footer');
document.createElement('figure');
