$.fn.headerSearchDropdown = function () {
  var main = $(this);
  if (main.size() == 0) {
    return false;
  }

  var searchTab = main.find(".search-tab-button");

  searchTab.bind("click", function (e) {
    e.preventDefault();
    if (main.hasClass("activated")) {
      main.removeClass("activated");
    } else {
      main.addClass("activated");
    };
  });
}