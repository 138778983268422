$.customForm = {};

$.fn.customForm = function () {


  // Test if the browser is IE and check the version number is lower than 9
  var userAgent = navigator.userAgent.toLowerCase();
  if (/msie/.test(userAgent) &&
    parseFloat((userAgent.match(/.*(?:rv|ie)[\/: ](.+?)([ \);]|$)/) || [])[1]) < 9) {
    return false;
  };

  var main = $(this);
  if (main.size() == 0) {
    return false;
  }

  main.each(function () {
    var items = main.find("input[type='radio'], input[type='checkbox'], select");

    $.customForm.radio(items.filter("[type='radio']"));
    $.customForm.checkbox(items.filter("[type='checkbox']"));
    $.customForm.select(items.filter("select"));
  });


  /* AUTO REFRESH DEBUG */
  main.bind("DOMNodeInserted", function () {
    var items = main.find("input[type='radio'], input[type='checkbox'], select");

    $.customForm.radio(items.filter("[type='radio']"));
    $.customForm.checkbox(items.filter("[type='checkbox']"));
    $.customForm.select(items.filter("select"));

  });

  $("a.clone-it").bind("click", function (e) {
    e.preventDefault();
    var clone = main.children("div:first").clone();
    clone.find("input, select").prop("checked", false).end().find("select");
    main.append(clone);
  });
};

$.customForm.radio = function (radios) {
  radios.each(function () {
    var obj = $(this);
    if (!obj.is(".customForm-generated")) {
      obj.addClass("customForm-generated").wrapAll('<span class="customForm-radio"></span>').after('<span class="customForm-radio-button">&nbsp;</span>');
    };
  });
};

$.customForm.checkbox = function (checkboxes) {
  checkboxes.each(function () {
    var obj = $(this);
    if (!obj.is(".customForm-generated")) {
      obj.addClass("customForm-generated").wrapAll('<span class="customForm-checkbox"></span>').after('<span class="customForm-checkbox-button">&nbsp;</span>');
    };
  });
};

$.customForm.select = function (select) {
  select.each(function () {
    var obj = $(this);
    if (!obj.is(".customForm-generated")) {
      obj.addClass("customForm-generated").wrapAll('<div class="customForm-select"></div>').after('<span class="customForm-select-arrow">&nbsp;</span>');
      if (!('pointerEvents' in document.body.style)) {

      }
    };
  });
};